(function ($, Drupal) {
  Drupal.behaviors.contentBlockButtonV1 = {
    attach: function (context) {
      var $modules = $('.js-content-block-button-trigger', context);

      $modules.each(function (indexId) {
        $(this)
          .once()
          .on('click.overlay', function () {
            $(document).trigger('launch.overlay', [indexId]);
          });
      });
    }
  };
})(jQuery, Drupal);
